import { create_modal } from '../common/html_elements';
import { rpc } from '../common/crystal_api/rpc';
import { scrollToIfNeeded } from '../common/utils';
import { cumulativeProfile_decode } from './profile';
import { empty_element, set_animate_pulse_classes } from '../common/ui';

function sort_shapes_items(item1, item2) {
  return item1['time'] - item2['time'];
}

function set_samples_items(smp, doc_id_shapes, index) {
  const sample = { name: smp['name'], index: index };
  smp['index'] = index;
  const args = '/hsm/sample' + index + '/profile';
  let items = [];
  Object.keys(smp).forEach((k) => {
    if (smp[k].time && smp[k].time !== 'None') {
      smp[k]['handle'] = k;
      items.push(smp[k]);
    }
  });
  items.sort(sort_shapes_items);
  let times = [0];
  for (let t of items) {
    times.push(parseFloat(t['time'].toFixed(1)));
  }
  rpc
    .data_col_at_time({ doc_id: doc_id_shapes, args: [args, times] })
    .then((response) => load_static_shapes('shapes' + index, items, sample, response));
}

function is_sample_key(key, i) {
  return new RegExp(`sample${i}_`).test(key) || key.startsWith(`@${key}`);
}

function get_sample_key_name(key) {
  return key.replace(/sample\d+_/, '');
}

function create_static_images_div(doc) {
  const static_container = document.getElementById(`${doc['testid']}_shapes`);
  const sample_shapes_list = [];
  for (let i = 0; i < doc['nSamples']; i++) {
    const static_samples = `<div id="shapes${i}"></div>`;
    static_container.insertAdjacentHTML('beforeend', static_samples);
    const smp = {};
    Object.keys(doc)
      .filter((key) => is_sample_key(key, i))
      .forEach((k) => {
        smp[get_sample_key_name(k)] = doc[k];
      });
    sample_shapes_list.push(smp);
    set_samples_items(sample_shapes_list[i], doc._id, i);
  }
}

function create_storyboard_images_div(doc) {
  const storyboard_container = document.getElementById(`${doc['testid']}_storyboard`);
  const sample_shapes_list = [];
  for (let i = 0; i < doc['nSamples']; i++) {
    const smp = {};
    Object.keys(doc)
      .filter((key) => is_sample_key(key, i))
      .forEach((k) => {
        smp[get_sample_key_name(k)] = doc[k];
      });
    sample_shapes_list.push(smp);
    const storyboard_samples = `<div id="storyboard${i}" data-smp_name="${sample_shapes_list[i]['name']}"></div>`;
    storyboard_container.insertAdjacentHTML('beforeend', storyboard_samples);
  }
  document.getElementById(`${doc['testid']}_input_slider`).dataset.nSamples = doc['nSamples'];
  setTimeout(() => {
    storyboard_update(doc['testid']);
  }, 500);
}

function lambda_shapes(doc, storyboard = true) {
  if (document.getElementById(`${doc['testid']}_shapes`).children.length > 0) return;
  create_static_images_div(doc);
  if (storyboard) {
    create_storyboard_images_div(doc);
  }
}

async function storyboard_update(testid) {
  let elem = document.getElementById(`${testid}_input_slider`);
  if (elem.target) {
    elem = elem.target;
  }
  const sl_val = parseFloat(elem.value);
  console.log('storyboard_update');
  const times = [];
  const names = [];
  for (let i = 0; i < 6; i++) {
    const mins = sl_val + i * 10;
    times.push(mins);
    const a = new Date(mins * 1000).toISOString().substr(11, 8);
    names.push(a);
  }
  for (let i = 0; i < parseInt(elem.dataset.nSamples); i++) {
    const doc_id = elem.dataset.doc_id;
    await rpc
      .data_col_at_time({ doc_id: doc_id, args: [`/hsm/sample${i}/T`, times] })
      .then((response) => set_canvas_temp(doc_id, i, times, names, response));
  }
}

function set_canvas_temp(doc_id, smp_index, times, names, temp) {
  const sb = document.getElementById('storyboard' + smp_index);
  let counter = 0;
  const items = [];
  while (counter < times.length) {
    items.push({
      time: times[counter],
      temp: temp[counter][1],
      handle: names[counter],
    });
    counter++;
  }
  const sample = { name: sb.dataset.smp_name, index: smp_index };
  var args = '/hsm/sample' + smp_index + '/profile';
  for (let t of temp) {
    t = t[1].toFixed(1);
  }
  rpc
    .data_col_at_time({ doc_id: doc_id, args: [args, times] })
    .then((response) => load_storyboard_shapes('storyboard' + smp_index, items, sample, response));
}

let shape_base = 100;
let shape_margin = 50;

function load_storyboard_shapes(target, items, sample, values) {
  console.log('storyboard shapes called', target, items, sample, values);
  const el = document.getElementById(target);
  empty_element(el);
  create_shapes_container(sample, el, items, 'storyboard');
  globalThis.addEventListener(
    'sample_shapes_container_ready',
    (event) => draw_samples_images_shapes(sample, values, items, event['detail']['target']),
    { once: true }
  );
}

function load_static_shapes(target, items, sample, values) {
  console.log('static shapes called', target, items, sample, values);
  const el = document.getElementById(target);
  items.unshift({ time: 0, temp: 0, value: 0, handle: 'Initial' });
  create_shapes_container(sample, el, items, 'static');
  globalThis.addEventListener(
    'sample_shapes_container_ready',
    (event) => draw_samples_images_shapes(sample, values, items, event['detail']['target']),
    { once: true }
  );
}

function create_shapes_container(sample, el, items, target) {
  const sample_desc = {
    smp_name: sample['name'],
    smp_idx: sample['index'],
    target: target,
    width: parseInt(el.offsetWidth / 6 - 20),
    samples: JSON.stringify(
      items.map((item, idx) => {
        const sample_name_or_idx = target === 'static' ? item['handle'] : idx;
        return { title: item['handle'], temp: `${item['temp'].toFixed(1)} °C`, idx: sample_name_or_idx };
      })
    ),
  };
  const images_template = require('../view/document/sample_shapes_container.handlebars');
  const images_html = images_template(sample_desc);
  el.insertAdjacentHTML('beforeend', images_html);
}

async function draw_samples_images_shapes(sample, values, items, target) {
  let shape_params = {
    scales: [],
    heights: [],
    widths: [],
    canvas: [],
    profiles: [],
  };
  for (const [idx, s] of values.entries()) {
    const path = cumulativeProfile_decode(s);
    const profile = path[1];
    const sample_name_or_idx = target === 'static' ? items[idx]['handle'] : idx;
    const canvas_id = `${target}_canvas_${sample['index']}_${sample_name_or_idx}`;
    const sample_canvas_info = {
      sample_canvas: await globalThis.getElementByIdWithRetry(canvas_id),
      cnv_title_text: items[idx]['handle'],
    };
    set_shape_params(profile, sample_canvas_info, shape_params);
    let scale = Math.max(...shape_params['scales']);
    let cnv = shape_params['canvas'][idx][0];
    const x_prof = shape_params['profiles'][idx][1];
    const y_prof = shape_params['profiles'][idx][2];
    draw_shape(cnv, x_prof, y_prof, scale);
    if (target === 'static') {
      cnv.addEventListener('click', () => show_static_images_dialog(x_prof, y_prof, shape_params['canvas'][idx][1], sample['index']));
    } else if (target === 'storyboard') {
      const image_desc = {
        title: items[idx]['handle'],
        temp: items[idx]['temp'],
        time: items[idx]['time'],
        smp_idx: sample['index'],
      };
      cnv.addEventListener('click', () => show_storyboard_images_dialog(x_prof, y_prof, image_desc));
    }
  }
  const name_or_idx = target === 'static' ? 'Initial' : '0';
  scrollToIfNeeded(document.getElementById(`${target}_canvas_${sample['index']}_${name_or_idx}`));
}

function set_shape_params(profile, canvas_info, shape_params) {
  const x_prof = profile[1];
  const y_prof = profile[2];
  const xm = Math.min(...x_prof);
  const ym = Math.min(...y_prof);
  const cw = Math.max(...x_prof) - xm;
  const ch = shape_base + Math.max(...y_prof) - ym + shape_margin;
  const scale = Math.max(cw / canvas_info.sample_canvas.width, ch / canvas_info.sample_canvas.height);
  shape_params['scales'].push(scale);
  shape_params['profiles'].push(profile);
  shape_params['canvas'].push([canvas_info.sample_canvas, canvas_info.cnv_title_text]);
  shape_params['heights'].push(ch);
  shape_params['widths'].push(cw);
  return true;
}

function draw_shape(cnv, x_prof, y_prof, scale = null) {
  const cw = Math.max(...x_prof) - Math.min(...x_prof);
  if (!scale) {
    const ch = shape_base + Math.max(...y_prof) - Math.min(...y_prof) + shape_margin;
    scale = Math.max(cw / cnv.width, ch / cnv.height);
  }
  const ctx = cnv.getContext('2d');
  cnv.classList.add('cursor-pointer');
  const xt = -x_prof[0] + 0.5 * (cnv.width * scale - cw);
  const yt = -y_prof[0] + cnv.height * scale;
  ctx.scale(1 / scale, 1 / scale);
  ctx.beginPath();
  ctx.moveTo(x_prof[0] + xt, y_prof[0] + yt);
  for (let j = 1; j < x_prof.length; j++) {
    ctx.lineTo(x_prof[j] + xt, y_prof[j] + yt - shape_base);
  }
  ctx.lineTo(x_prof.slice(-1)[0] + xt, y_prof[0] + yt);
  ctx.fillStyle = 'black';
  ctx.fill();
  ctx.closePath();
  ctx.stroke();
}

function show_storyboard_images_dialog(x_prof, y_prof, image_desc) {
  const sample_image_template = require('../view/storyboard_shapes_dialog.handlebars');
  const available_shapes = ['None', 'Sintering', 'Softening', 'Sphere', 'HalfSphere', 'Melting'];
  const sample_image_html = sample_image_template({
    title: image_desc['title'],
    available_shapes: JSON.stringify(available_shapes),
    temp: image_desc['temp'],
    time: image_desc['time'],
    smp_idx: image_desc['smp_idx'],
    x_prof: JSON.stringify(x_prof),
    y_prof: JSON.stringify(y_prof),
  });
  document.body.insertAdjacentHTML('beforeend', sample_image_html);
  globalThis.addEventListener('storyboard_shapes_dialog_ready', (event) => draw_zoomed_shape_dialog(x_prof, y_prof, event['detail']['dialog']), {
    once: true,
  });
}

function show_static_images_dialog(x_prof, y_prof, title, smp_idx) {
  const static_images_template = require('../view/static_shapes_dialog.handlebars');
  const static_images_html = static_images_template({ title: title, show_unset_button: title !== 'Initial', sample_idx: smp_idx });
  document.body.insertAdjacentHTML('beforeend', static_images_html);
  globalThis.addEventListener('static_shapes_dialog_ready', (event) => draw_zoomed_shape_dialog(x_prof, y_prof, event['detail']['dialog']), {
    once: true,
  });
}

function draw_zoomed_shape_dialog(x_prof, y_prof, dialog) {
  const cnv = `<canvas id="dialog_canvas"></canvas>`;
  dialog.insertAdjacentHTML('beforeend', cnv);
  draw_shape(document.getElementById('dialog_canvas'), x_prof, y_prof);
}

function set_new_shape_image() {
  const element = document.getElementById('update_sample_shapes_image');
  const new_temp = element.dataset.temp;
  const new_time = element.dataset.time;
  const smp_idx = element.dataset.smp_idx;
  const key = `sample${smp_idx}_${element.value}`;
  const conf = globalThis.current_document_conf;
  const doc = conf.currents;
  const cnv = document.getElementById(`static_canvas_${smp_idx}_${element.value}`);
  if (cnv) {
    const value = doc[key]['value'];
    const new_shape = { temp: new_temp, value: value, time: new_time };
    conf.update_shape(element.value, new_shape, key, smp_idx);
    const x_prof = JSON.parse(element.dataset.x_prof);
    const y_prof = JSON.parse(element.dataset.y_prof);
    cnv.width = cnv.width;
    draw_shape(cnv, x_prof, y_prof);
    document.getElementById(`static_canvas_${smp_idx}_${element.value}_temp`).innerText = `${parseFloat(new_temp).toFixed(1)} °C`;
    cnv.classList.add('border-elsred-light');
    set_animate_pulse_classes(cnv);
  } else {
    const new_shape_values = { temp: parseFloat(new_temp), time: parseFloat(new_time) };
    conf.set_shape(element.value, new_shape_values, key, smp_idx);
    empty_element(document.getElementById(`${doc['testid']}_shapes`));
    const new_shape_desc = { value: 0, handle: element.value };
    doc[key] = { ...new_shape_values, ...new_shape_desc };
    create_static_images_div(doc);
  }
}

function unset_shape_image(idx, shape) {
  const key = `sample${idx}_${shape}`;
  const conf = globalThis.current_document_conf;
  conf.delete_shape(key, shape);
  const doc = conf.currents;
  delete doc[key];
  delete doc[`@${key}`];
  empty_element(document.getElementById(`${doc['testid']}_shapes`));
  create_static_images_div(doc);
}

globalThis.lambda_shapes = lambda_shapes;
globalThis.storyboard_update = storyboard_update;
globalThis.set_new_shape_image = set_new_shape_image;
globalThis.unset_shape_image = unset_shape_image;
